import licit from '@mc-alberta/licit'
import extendOptionalValuesToAccept from '../../../helpers/validations/extendOptionalValuesToAccept'
import { complianceType } from '../emvco/enums'

const { types } = licit

const acceptedTypesForOptionalValues = [types.undefined, types.null]

export default extendOptionalValuesToAccept(...acceptedTypesForOptionalValues)([
  { key: 'complianceSettings', type: types.object },
  { key: 'complianceSettings.privacy', type: types.object },
  { key: 'complianceSettings.privacy.acceptedVersion', type: types.string },
  { key: 'complianceSettings.privacy.latestVersion', type: types.string },
  { key: 'complianceSettings.privacy.latestVersionUri', type: types.string },
  { key: 'complianceSettings.tnc', type: types.object },
  { key: 'complianceSettings.tnc.acceptedVersion', type: types.string },
  { key: 'complianceSettings.tnc.latestVersion', type: types.string },
  { key: 'complianceSettings.tnc.latestVersionUri', type: types.string },
  { key: 'complianceSettings.cookie', type: types.object },
  { key: 'complianceSettings.cookie.acceptedVersion', type: types.string },
  { key: 'complianceSettings.cookie.latestVersion', type: types.string },
  { key: 'complianceSettings.cookie.latestVersionUri', type: types.string },
  { key: 'complianceSettings.geoLocation', type: types.object },
  { key: 'complianceSettings.geoLocation.acceptedVersion', type: types.string },
  { key: 'complianceSettings.geoLocation.latestVersion', type: types.string },
  { key: 'complianceSettings.geoLocation.latestVersionUri', type: types.string },
  { key: 'complianceSettings.communications', type: types.object },
  { key: 'complianceSettings.communications.communicationsOptIn', type: types.boolean },
  { key: 'complianceSettings.communications.affiliateCommunicationsOptIn', type: types.boolean },
  { key: 'complianceSettings.communications.allowEmail', type: types.boolean },
  { key: 'complianceSettings.communications.allowText', type: types.boolean },
  { key: 'complianceSettings.communications.allowCall', type: types.boolean },
  { key: 'complianceSettings.communications.allowPush', type: types.boolean },
  { key: 'complianceSettings.complianceResources', type: types.object },
  {
    key: 'complianceSettings.complianceResources.complianceType',
    type: types.utils.generateEnumValidator(complianceType)
  },
  { key: 'complianceSettings.complianceResources.uri', type: types.string },
  { key: 'complianceSettings.complianceResources.version', type: types.string },
  { key: 'complianceSettings.complianceResources.datePublished', type: types.string }
])
