import helpers from './helpers'

const consentsNameMapping = {
  PRIVACY_POLICY: 'privacy',
  TERMS_AND_CONDITIONS: 'tnc',
  COOKIE: 'cookie',
  GEOLOCATION: 'geoLocation'
}

/**
 * Transforms complianceSettings from EMVCO 1.3 to EMVCO 1.0
 *
 * NOTE: Consent for REMEMBER_ME or unknown complianceResources (not included in
 * consentsNameMapping) will be preserved in EMVCO 1.3 format (complianceResource)
 *
 * @param {object} complianceSettings1.3
 * @returns {object} complianceSettings1.0
 */
export default function (originalComplianceSettings) {
  if (
    !originalComplianceSettings ||
    Array.isArray(originalComplianceSettings) ||
    typeof originalComplianceSettings !== 'object'
  )
    return

  const clonedComplianceSettings = helpers.cloneDeep(originalComplianceSettings)

  const response = {}

  const { complianceResources: originalComplianceResources, ...complianceSettings } =
    clonedComplianceSettings

  let transformedConsents

  if (Array.isArray(originalComplianceResources)) {
    transformedConsents = originalComplianceResources.reduce(
      (complianceStructures, complianceResource) => {
        try {
          const shouldTransform = hasConsentRepresentation(complianceResource)

          shouldTransform
            ? Object.assign(complianceStructures, transformComplianceResource(complianceResource))
            : complianceStructures.complianceResources.push(complianceResource)
        } catch (e) {}

        return complianceStructures
      },
      { complianceResources: [] }
    )
  }

  return Object.assign(response, complianceSettings, transformedConsents)
}

/**
 * Determine if complianceResource has an equivalent consent representation
 *
 * @param {object} complianceResource
 * @returns {boolean} boolean indicating if complianceResource  has an equivalent consent representation
 */
function hasConsentRepresentation({ complianceType }) {
  return Object.keys(consentsNameMapping).includes(complianceType)
}

/**
 * Transforms complianceResource (EMVCO 1.3) to consent (EMVCO 1.0)
 *
 * @param {object} complianceResource
 * @returns {object} consent
 */
function transformComplianceResource({ complianceType, version, uri }) {
  return {
    [consentsNameMapping[complianceType]]: {
      ...(version && { acceptedVersion: version }),
      ...(uri && { latestVersionUri: uri }),
      latestVersion: 'LATEST'
    }
  }
}
