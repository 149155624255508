import licit from '@mc-alberta/licit'
import { dpaLocaleValidationMessages, sanctionedCountries } from '../../../values'

export default [
  { key: 'dpaLocale', type: validateFormat, required: true },
  { key: 'dpaLocale', type: localeIsSanctioned }
]

function validateFormat(value) {
  return RegExp(`^[a-z]{2}[_][A-Z]{2}$`).test(value)
}

function localeIsSanctioned(value) {
  localeIsSanctioned.errorDetails = dpaLocaleValidationMessages.sanctionedCountryPrefix
  value = licit.isAString(value) ? value : ''
  const [, countryCode] = value.split('_')
  const valid = !sanctionedCountries.includes(countryCode)
  if (!valid) {
    localeIsSanctioned.errorDetails = `${dpaLocaleValidationMessages.sanctionedCountryPrefix}: ${countryCode}`
  }
  return valid
}

validateFormat.errorDetails = dpaLocaleValidationMessages.invalidFormat
localeIsSanctioned.errorDetails = dpaLocaleValidationMessages.sanctionedCountryPrefix
