import licit from '@mc-alberta/licit'

const { types } = licit

const deleteCard = [{ key: 'srcDigitalCardId', type: types.string, required: true }]

const unbindAppInstance = [{ key: 'idToken', type: types.string }]

export default {
  deleteCard,
  unbindAppInstance
}
