class SRCError extends Error {
  constructor({
    message,
    error
  }: {
    error: {
      status: number
      reason: string
      message: string
    }
    message?: string
  }) {
    super(message)
    Object.assign(this, error)
  }
}

export default SRCError
