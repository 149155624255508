export const KPI_METHODS = {
  INIT: 'init',
  CHECKOUT: 'checkout',
  ENROLL_CARD: 'enrollCard',
  IS_RECOGNIZED: 'isRecognized',
  GET_SRC_PROFILE: 'getSrcProfile',
  IDENTITY_LOOKUP: 'identityLookup',
  INITIATE_IDENTITY_VALIDATION: 'initiateIdentityValidation',
  COMPLETE_IDENTITY_VALIDATION: 'competeIdentityValidation',
  UNBIND_APP_INSTANCE: 'unbindAppInstance',
  DELETE_CARD: 'deleteCard',
  AUTHENTICATION_METHODS_LOOKUP: 'authenticationMethodsLookup'
}

export const KPI_METHOD_DATA = {
  [KPI_METHODS.INIT]: {
    resource: 'srciSdk',
    operation: 'INIT'
  },
  [KPI_METHODS.CHECKOUT]: {
    resource: 'TRANSACTION',
    operation: 'CHECKOUT'
  },
  [KPI_METHODS.ENROLL_CARD]: {
    resource: 'CARD',
    operation: 'ENROLL'
  },
  [KPI_METHODS.IS_RECOGNIZED]: {
    resource: 'CONSUMER_PROFILE',
    operation: 'LOOKUP_BY_APPINSTANCE'
  },
  [KPI_METHODS.GET_SRC_PROFILE]: {
    resource: 'CONSUMER_PROFILE',
    operation: 'LOOKUP_BY_JWT'
  },
  [KPI_METHODS.IDENTITY_LOOKUP]: {
    resource: 'CONSUMER_PROFILE',
    operation: 'LOOKUP_BY_EMAIL'
  },
  [KPI_METHODS.INITIATE_IDENTITY_VALIDATION]: {
    resource: 'CONSUMER_PROFILE',
    operation: 'GENERATE_OTP'
  },
  [KPI_METHODS.COMPLETE_IDENTITY_VALIDATION]: {
    resource: 'CONSUMER_PROFILE"',
    operation: 'VALIDATE_OTP'
  },
  [KPI_METHODS.UNBIND_APP_INSTANCE]: {
    resource: 'CONSUMER_PROFILE',
    operation: 'DELETE_BY_APPINSTANCE'
  },
  [KPI_METHODS.AUTHENTICATION_METHODS_LOOKUP]: {
    resource: 'CARD',
    operation: 'LOOKUP_AUTHENTICATION_METHODS'
  },
  // not sure about the resource and operation
  [KPI_METHODS.DELETE_CARD]: {
    resource: 'TRANSACTION',
    operation: 'DELETE_CARD'
  }
}

export const CARD_BRANDS = {
  MASTERCARD: 'mastercard',
  MAESTRO: 'maestro'
}
