import { lumber } from '@mc-alberta/lumber'
import SRCError from '../SRCError'
import { DEFAULT_PROGRAM_ID } from '../constants'
import nudetectWidgetData from '../helpers/extractNudetectWidgetData'
import post from '../post'
import transform from '../transform'
import helpers from '../transform/helpers'
import utils from '../utils'
import { CARD_BRANDS } from '../utils/constants'
import validate from '../validate'

// Temporary logic to interprit the user is not recognized backend API response
const validUserIsNotRecognizedResponse = ({
  errors: [{ status: errorStatus } = false] = []
} = {}) => {
  // response has an error with a status code and status code is 4xx
  return Boolean(errorStatus) && errorStatus >= 400 && errorStatus <= 499
}

// eslint-disable-next-line complexity
export async function isRecognized(params) {
  const { state } = this

  try {
    const {
      recognitionTokens: [recognitionToken]
    } = params
    if (recognitionToken) {
      state.headers['SRC-Recognition-Token'] = recognitionToken
    }
  } catch (e) {
    if (state.headers['SRC-Recognition-Token']) {
      delete state.headers['SRC-Recognition-Token']
    }
  }

  // call /consumers
  const message = {
    method: 'get',
    url: 'consumers',
    headers: { ...state.headers },
    ...nudetectWidgetData(state.isMastercardSrci, arguments)
  }
  const result = await post.send(message, state)
  let response = {
    recognized: false // required
  }

  try {
    // Temporary fix to process the revised
    // backend API codes to indicate that a
    // user is not recognized
    if (validUserIsNotRecognizedResponse(result)) {
      return response
    }
  } catch (e) {
    if (state.headers['SRC-Recognition-Token']) {
      delete state.headers['SRC-Recognition-Token']
    }
  }

  validate.response(result, { 400: 'INVALID_PARAMETER' })

  // if we recognize the user, this will be our default token to use
  // and note we found it here, so we can alert DCF via
  // recognitionStatus, they dont need to prompt to set cookie
  if (result.authorization && result.authorization.length) {
    state.idToken = state.recognizedIdToken = result.authorization[0]
    response = {
      recognized: true, // required
      idTokens: result.authorization // conditional
    }
  }

  return response
}

export async function getSrcProfile({
  idTokens // optional
} = {}) {
  const { state } = this

  idTokens = Array.isArray(idTokens) ? idTokens : []

  utils.performNonBlockingValidations(
    {
      params: arguments[0],
      methodName: 'getSrcProfile'
    },
    state
  )

  let transactionOptions = transform.merchantCountryCode.request({ ...state.dpaTransactionOptions })
  transactionOptions = transform.dpaLocale.request(transactionOptions)
  const dsaTransactionOptions = transform.dpaTransactionOptions.request({ ...transactionOptions }) // 0.9

  const message = {
    method: 'post',
    url: 'customerProfiles',
    headers: { ...state.headers },

    // request body
    data: {
      idTokens,
      dsaTransactionOptions
    }
  }

  const result = await post.send(message, state)
  validate.response(result, { 400: 'INVALID_PARAMETER' })

  // dont return empty list of profiles
  if (!result.profiles || !result.profiles.length) {
    throw new SRCError({
      error: {
        status: 404,
        reason: 'NOT_FOUND',
        message: 'Not found'
      }
    })
  }

  const acceptedCardBrands = state?.dpaTransactionOptions?.customInputData?.[
    'com.mastercard.acceptedCardBrands'
  ] || [CARD_BRANDS.MASTERCARD]
  const response = {
    profiles: result.profiles.map((profile) => {
      const filteredCards = profile.cards?.filter((card) =>
        acceptedCardBrands.includes(card.paymentCardDescriptor)
      )

      return {
        ...profile,
        cards: filteredCards
      }
    }), // filter out unaccepted cards, even if that means a profile has no cards
    srcCorrelationId: state.headers['SRC-Correlation-Id'] // optional
  }

  // transform profiles to match 1.0 specs
  response.profiles.forEach((profile, index) => {
    response.profiles[index] = transform.profile.response(profile)
  })

  // store profiles so we can find card and token by id during checkout()
  state.profiles = response.profiles
  lumber.flushQueue()
  // do not return the immutable property to SRCi

  const hasNonSrcProfiles = response.profiles.some(({ programUserInterfaceOptions }) => {
    return (
      programUserInterfaceOptions && programUserInterfaceOptions.programId !== DEFAULT_PROGRAM_ID
    )
  })

  if (response.profiles.length > 1) {
    const preferredProfiles = helpers.cloneDeep(
      response.profiles.filter((profile) => profile?.preferredProfile === true)
    )

    if (preferredProfiles.length !== 1) {
      throw new SRCError({
        error: {
          status: 503,
          reason: 'SERVICE_ERROR',
          message: 'No preferred profile'
        }
      })
    }

    // Merges all the cards from every profile and nests them within with the preferred profile
    const allCards = response.profiles.reduce(
      (cards, current) => cards.concat(current.maskedCards),
      []
    )
    preferredProfiles[0].maskedCards = allCards
    response.profiles = preferredProfiles
  }

  const profiles = response.profiles.map(
    ({ immutable, programUserInterfaceOptions, ...profile }) => ({
      ...profile,
      // non-SRC program idTokens cannot be released, as those profiles are effectively immutable
      authorization: hasNonSrcProfiles ? state.idToken : profile.authorization
    })
  )
  return { ...response, profiles }
}
