import { lumber } from '@mc-alberta/lumber'
import * as stark from '@mc-alberta/stark'
import { AccountReference, AuthenticationContext } from '@mc-alberta/types'
import post from '../post'
import MethodContext from '../types/MethodContext'
import utils from '../utils'
import validate from '../validate'

export async function authenticationMethodsLookup(
  this: MethodContext,
  {
    accountReference,
    authenticationContext,
    srcCorrelationId,
    srciTransactionId
  }: {
    accountReference: AccountReference
    authenticationContext: AuthenticationContext
    srcCorrelationId?: string
    srciTransactionId?: string
  }
) {
  const { state } = this
  utils.performNonBlockingValidations(
    {
      // eslint-disable-next-line prefer-rest-params
      params: arguments[0],
      methodName: 'authenticationMethodsLookup'
    },
    state
  )

  if (accountReference?.consumerIdentity) {
    const { consumerIdentity } = accountReference

    validate.params({ consumerIdentity })
    validate.identity({ consumerIdentity })
  }

  const response = await stark.authenticationMethodsLookup(
    {
      authenticationContext,
      accountReference,
      srcCorrelationId,
      srciTransactionId,
      serviceId: 'SRC',
      srcClientId: state.srcInitiatorId,
      traceId: state.traceId!
    },
    post.send as any
  )

  lumber.flushQueue()

  return response
}
