import SRCError from './SRCError'
import utils from './utils'
import { allEmvcoErrorReasonCodes, checkoutEmvcoErrorReasonCodes } from './values'

export default {
  transactionOptions({ transactionOptions }) {
    // throw errors if they give us 0.9 format
    Object.keys(transactionOptions).forEach((key) => {
      if (key.substring(0, 3) === 'dsa') {
        throw new SRCError({
          error: { status: 400, reason: 'MERCHANT_DATA_INVALID', message: 'Merchant data invalid' }
        })
      }
    })

    validateAuthenticationPreferences(transactionOptions)
  },
  identity({ consumerIdentity }) {
    validateConsumerIdentityParams(consumerIdentity)
    const allowedIdentityTypes = ['EMAIL_ADDRESS', 'MOBILE_PHONE_NUMBER']
    if (!allowedIdentityTypes.includes(consumerIdentity.identityType)) {
      throw new SRCError({
        error: { status: 400, reason: 'ID_FORMAT_UNSUPPORTED', message: 'Id format unsupported' }
      })
    }
  },
  // validate.params is currently responsable for performing
  // blocking validations (reject SDK method promise if validations
  // do not succeed)
  // validate.params will soon be replaced by lecit (currently enabled
  // in non blocking mode. See performNonBlockingValidations in utils.js)
  params(params = {}) {
    const errors = []
    const map = {
      srcDigitalCardId: 'CARDID_MISSING',
      srcInitiatorId: 'SRCI_ID_MISSING',
      srciDpaId: 'DPA_ID_MISSING',
      srciTransactionId: 'SRCI_TXID_MISSING',
      dpaTransactionOptions: 'DPA_TXOPT_MISSING',
      consumerIdentity: 'CONSUMER_ID_MISSING',
      validationData: 'VALDATA_MISSING',
      maskedCard: 'CARD_NOT_RECOGNIZED'
    }

    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        errors.push({
          status: 400, // all of the above map to 400 status for now
          reason: map[key],
          message: 'Missing parameter'
        })
      }
    })

    if (utils.isDpaLocaleInvalid(params.dpaTransactionOptions)) {
      errors.push({
        status: 400,
        reason: 'INVALID_PARAMETER',
        message: 'A valid dpaLocale was not provided (ISO language_country pair. e.g. en_US)'
      })
    }

    if (errors.length) {
      const error = errors[0]
      if (errors.length > 1) {
        // remove status to generate an errorDetail
        error.errorDetails = errors.map(({ status, ...error }) => error)
      }
      throw new SRCError({ error })
    }
  },
  // Attempt to map generic errors:
  //   https://fusion.mastercard.int/confluence/x/X_4-QF
  //   https://fusion.mastercard.int/confluence/x/vJtKFQ
  // eslint-disable-next-line complexity
  response(response = {}, config, source = 'UNKNOWN') {
    const reasons = {
      400: 'INVALID_REQUEST',
      401: 'AUTH_INVALID',
      403: 'AUTH_ERROR',
      404: 'NOT_FOUND',
      500: 'SERVICE_ERROR',
      ...config
    }
    if (!response.errors || !response.errors.length) return

    // we currently only inspect the first error
    const error = response.errors[0]

    const status = error.status
    let reason = getInvalidArgument(error) || reasons[error.status] || 'SERVICE_ERROR'
    let message = 'Service error' // TODO: make this more descriptive based on type

    // if error returns a message, map it
    if (error.data && error.data.message) {
      message = error.data.message
    }

    // Backend API responses with an HTTP status code of 403
    // and an error status of 'LOCKED' should indicate
    // that the user account is currently locked
    reason = normalizeLockedAccountReason(error, reason)

    // Note: This case is treated as an exception, if it becomes common, a larger refactor may be needed
    if (source === 'COMPLETE_IDENTITY_VALIDATION') {
      reason = normalizeInvalidCodeReason(error, reason)
    }

    // Note: we do not currently return optional errorDetails

    throw new SRCError({ error: { reason, status, message } })
  },
  checkoutResult(result) {
    // if result.action is equal to ERROR or result.action is not EMVCO compliant throw an SRCError
    const isError = result.action === 'ERROR'
    const nonEMVCOCompliantAction = !isEMVCOCompliantAction(result)
    const shouldThrowCheckoutError = isError || nonEMVCOCompliantAction

    if (shouldThrowCheckoutError) {
      constructAndThrowCheckoutError(result)
    }
  }
}

export function isEMVCOCompliantError({ errorCode }) {
  return allEmvcoErrorReasonCodes.includes(errorCode)
}

function isEMVCOCompliantCheckoutError({ errorCode }) {
  return checkoutEmvcoErrorReasonCodes.includes(errorCode)
}

function normalizeLockedAccountReason(error, reason) {
  return error.status === 403 && error.data && error.data.status === 'LOCKED'
    ? 'ACCT_INACCESSIBLE'
    : reason
}

// Note: OTP validation specific, may need a larger refactor if these cases become common
function normalizeInvalidCodeReason(error, reason) {
  return error.status === 400 && error.data && error.data.status === 'INVALID_STATE'
    ? 'CODE_INVALID'
    : reason
}

// check error details for more descriptive error type
// eslint-disable-next-line complexity
function getInvalidArgument(error) {
  let detail
  try {
    detail = error.data.details[0]
    if (detail === undefined) throw new Error('Error details are not present')
  } catch (e) {
    return false
  }

  if (
    error.status === 400 &&
    error.data.status === 'INVALID_ARGUMENT' &&
    detail.reason === 'INVALID_VALUE'
  ) {
    if (['enrollRequest.card.year', 'enrollRequest.card.month'].includes(detail.source)) {
      return 'CARD_EXP_INVALID'
    }

    if (detail.source === 'consumerIdentity.idLookupSessionId') {
      return 'SESSION_ID_INVALID'
    }
  }
  return false
}

function isEMVCOCompliantAction({ action }) {
  return ['COMPLETE', 'CHANGE_CARD', 'SWITCH_CONSUMER', 'ERROR', 'CANCEL', 'ADD_CARD'].includes(
    action
  )
}

function constructAndThrowCheckoutError(result) {
  const defaultErrorReason = 'SERVICE_ERROR'
  const defaultErrorMessage = 'DCF Error Code not recognized'

  const error = {
    status: 500,
    reason: defaultErrorReason,
    message: defaultErrorMessage
  }

  const errorDetailsArePresent = Boolean(result.errorCode && result.errorDescription)
  const EMVCOCompliantError = isEMVCOCompliantCheckoutError(result)
  const nonEMVCOCompliantAction = !isEMVCOCompliantAction(result)
  const useDCFErrorMetadata = EMVCOCompliantError && errorDetailsArePresent

  if (useDCFErrorMetadata) {
    error.reason = result.errorCode
    error.message = result.errorDescription
  }

  if (nonEMVCOCompliantAction) {
    error.reason = defaultErrorReason
    error.message = `${result.action} is a non compliant EMVCO action`
  }

  throw new SRCError({ error })
}

function validateConsumerIdentityParams(consumerIdentity) {
  const requiredParamsArePresent = () => {
    const requiredParams = ['identityType', 'identityValue']
    const providedParams = Object.keys(consumerIdentity)
    const missingParams = requiredParams.filter((rp) => !providedParams.includes(rp))
    if (missingParams.length) {
      throw new SRCError({
        error: {
          status: 400,
          reason: 'INVALID_PARAMETER',
          message: `Missing required params: ${missingParams.join(' ')}`
        }
      })
    }
    return true
  }

  // Apply basic validations to identityValue
  // should be a string
  // should not be an empty string
  const identityValuePassesBasicValidations = () => {
    if (
      typeof consumerIdentity.identityValue !== 'string' ||
      consumerIdentity.identityValue === ''
    ) {
      throw new SRCError({
        error: {
          status: 400,
          reason: 'CONSUMER_ID_MISSING',
          message: `Invalid identityValue value`
        }
      })
    }
    return true
  }

  requiredParamsArePresent() && identityValuePassesBasicValidations()
}

function validateAuthenticationMethods(authenticationMethods, error) {
  // https://fusion.mastercard.int/stash/projects/BERT/repos/types/browse/src/types/AuthenticationMethod.ts

  if (!Array.isArray(authenticationMethods)) {
    error.message = 'authenticationMethods must be an Array'
    throw new SRCError({ error })
  }

  const notAnObject = (method) => typeof method !== 'object' && !Array.isArray(method)
  const invalidAuthenticationMethods = authenticationMethods.filter(notAnObject)

  if (invalidAuthenticationMethods.length > 0) {
    error.message = `authenticationMethods contains an invalid type`

    throw new SRCError({ error })
  }
}

function validatePayloadRequested(payloadRequested, error) {
  // https://fusion.mastercard.int/stash/projects/BERT/repos/types/browse/src/enums/PayloadRequested.ts
  const PAYLOAD_REQUESTED_TYPE = ['AUTHENTICATED', 'NON_AUTHENTICATED']

  if (!PAYLOAD_REQUESTED_TYPE.includes(payloadRequested)) {
    error.message = `payloadRequested contains an invalid type`
    throw new SRCError({ error })
  }
}

function validateSuppressChallenge(suppressChallenge, error) {
  if (typeof suppressChallenge !== 'boolean') {
    error.message = `suppressChallenge must be a Boolean`

    throw new SRCError({ error })
  }
}

function validateAuthenticationPreferences(transactionOptions) {
  // https://fusion.mastercard.int/stash/projects/BERT/repos/types/browse/src/types/AuthenticationPreferences.ts
  const error = {
    status: 400,
    reason: 'MERCHANT_DATA_INVALID',
    message: ''
  }

  if (transactionOptions.authenticationPreferences == null) {
    return
  }

  const { authenticationPreferences } = transactionOptions

  // optional authenticationMethods
  if (authenticationPreferences.authenticationMethods != null) {
    validateAuthenticationMethods(authenticationPreferences.authenticationMethods, error)
  }

  // optional payloadRequested
  if (authenticationPreferences.payloadRequested != null) {
    validatePayloadRequested(authenticationPreferences.payloadRequested, error)
  }

  // optional suppressChallenge
  if (authenticationPreferences.suppressChallenge != null) {
    validateSuppressChallenge(authenticationPreferences.suppressChallenge, error)
  }
}
