import type { APIError } from '@mc-alberta/types'
import { AxiosError, AxiosRequestConfig } from 'axios'
import post from './post'
import type State from './types/State'

const retryAfter = 2000

/**
 * recursiveEnroll
 *
 * Calls the /cards api and will retry on any 503 or 504 response.
 *
 * Will only try to make 3 total api calls if the 3 rd call fails
 * it will return the 503 or 504 response.All other responses are returned as normal.
 *
 * @private
 * @param {Object} message - mesage to send to iframe
 * @param {String} tries - recursion counter
 * @return {Promise<Object>}
 */
export default async function recursiveEnroll<T>(
  message: AxiosRequestConfig,
  state: State,
  tries = 0
) {
  try {
    return await post.send<T>(message, state)
  } catch (exception) {
    const error = exception as AxiosError<APIError>

    if ([503, 504].includes(error.response?.status || 0) && tries < 2) {
      await retryTimer(retryAfter)
      return recursiveEnroll(message, state, ++tries)
    }

    return Promise.reject(error)
  }
}

function retryTimer(retryAfter: number) {
  return new Promise((resolve) => setTimeout(resolve, retryAfter))
}
