import SRCError from '../SRCError'
import pick from '../utils/pick'
import { isEMVCOCompliantError } from '../validate'

// Since SDK E2E is validating errors via
// string comparision order of first three
// properties must be reason, status, message
const fallbackError = {
  reason: 'UNKNOWN_ERROR',
  status: 500,
  message: 'Unknown error.'
}

export default function DecorateSDKWithFallbackError(sdkMethods) {
  return Object.keys(sdkMethods).reduce(
    (wrappedMethods, methodName) => ({
      ...wrappedMethods,
      [methodName]: addSRCErrorCompliance(sdkMethods[methodName])
    }),
    {}
  )
}

function addSRCErrorCompliance(fn) {
  if (typeof fn !== 'function') {
    throw new Error('Invalid argument: fn must be a function')
  }

  return async function (...params) {
    try {
      return await fn.apply(this, params)
    } catch (e) {
      const allowedPropNames = ['reason', 'status', 'message', 'errorDetail']

      let validProps = {}

      try {
        const foundProps = pick(e, ...allowedPropNames)
        validProps = chooseValidProps(foundProps)
      } catch (e) {}

      throw new SRCError({
        error: {
          ...fallbackError,
          ...validProps
        }
      })
    }
  }
}

// valid props are: status: Integer, reason: EMVCO compliant String
// message: non-empty String, errorDetail: Array (optional)
function chooseValidProps(foundProps) {
  const { status, reason, message, errorDetail } = foundProps

  return {
    ...(isEMVCOCompliantError({ errorCode: reason }) && { reason }),
    ...(isValidStatus(status) && { status }),
    ...(isValidMessage(message) && { message }),
    ...(isValidErrorDetail(errorDetail) && { errorDetail })
  }
}

function isValidStatus(status) {
  return Number.isInteger(status)
}

function isValidMessage(message) {
  return typeof message === 'string' && message
}

function isValidErrorDetail(errorDetail) {
  return Array.isArray(errorDetail)
}
