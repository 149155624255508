/**
 * Higher order function that will extend the supplied licit optional rules
 * to accept the supplied validators
 *
 * @param {Function} licit rule validator
 *
 * @returns {Function} that will extend the supplied licit optional rules
 */
export default function extendOptionalValuesToAccept(...validators) {
  return function (rules) {
    return rules.map((rule) =>
      rule.required || rule.strictOptionality ? rule : extendRuleToAccept(rule, validators)
    )
  }
}

function extendRuleToAccept(rule, values) {
  return {
    ...rule,
    type: [...(Array.isArray(rule.type) ? rule.type : [rule.type]), ...values]
  }
}
