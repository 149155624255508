import SRCError from '../SRCError'
import pick from '../utils/pick'

// TODO: Consider implementing this logic in the SRC Error class or validate.response
// to provide the errorDetail prop to all SRC Error instances
export default function decorateExceptionsWithErrorDetail(fn, sourceType) {
  if (typeof fn !== 'function' || typeof sourceType !== 'string') {
    throw new Error('Invalid params. fn must be a fuction and sourceType must be a string')
  }

  return async function (...params) {
    try {
      return await fn.apply(this, params)
    } catch (e) {
      if (e instanceof SRCError) {
        const errorProps = pick(e, 'reason', 'message', 'status')
        throw new SRCError({
          error: {
            ...errorProps,
            errorDetail: [
              {
                ...errorProps,
                sourceType
              }
            ]
          }
        })
      }
      throw e
    }
  }
}
