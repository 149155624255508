import methods from '../methods'

/**
 * Returns an SrcSdk factory decorated with an SrcSdk object
 * @param SrcSdk factory function
 *
 * @returns SrcSdk factory decorated with an SrcSdk object
 */
export default function decorateFactoryWithDefaultSrcSdkObject(factory) {
  const defaultSrcSdkObject = factory()

  Object.keys(methods).forEach((baseMethodName) => {
    factory[baseMethodName] = defaultSrcSdkObject[baseMethodName]
  })

  return factory
}
