import transform from './complianceSettings'
import helpers from './helpers'

// eslint-disable-next-line complexity
export default function transformComplianceSettingsToEmvcoPointNine(originalComplianceSettings) {
  if (
    !originalComplianceSettings ||
    Array.isArray(originalComplianceSettings) ||
    typeof originalComplianceSettings !== 'object'
  )
    return

  const clonedComplianceSettings = helpers.cloneDeep(originalComplianceSettings)

  // Transforms 1.3 to 1.0 and keeps 1.0 intact
  const transformedComplianceSettings = transform(clonedComplianceSettings)

  if (transformedComplianceSettings) {
    // Transforms 1.0 to 0.9
    const { tnc, privacy, complianceResources } = transformedComplianceSettings

    const complianceSettingsEmvcoPointNine = {
      ...(privacy?.latestVersionUri && { privacyLatestVersionUri: privacy?.latestVersionUri }),
      ...(tnc?.latestVersionUri && { tncLatestVersionUri: tnc?.latestVersionUri }),
      ...(privacy?.acceptedVersion && { privacyAcceptedVersion: privacy.acceptedVersion }),
      ...(tnc?.acceptedVersion && { tncAcceptedVersion: tnc.acceptedVersion }),
      privacyLatestVersion: privacy?.latestVersion || 'LATEST',
      tncLatestVersion: tnc?.latestVersion || 'LATEST'
    }

    if (complianceResources?.some((resource) => resource.complianceType === 'REMEMBER_ME')) {
      complianceSettingsEmvcoPointNine.rememberMe = true
    }

    return complianceSettingsEmvcoPointNine
  }
}
