import SrcSdk from './SrcSdk'
import decorateFactoryWithDefaultSrcSdkObject from './helpers/SrcSdkFactoryDecorator'

export default decorateFactoryWithDefaultSrcSdkObject(SrcSdkFactory)

/**
 * Returns an SrcSdk object constructed with a fully private state
 * (i.e. no state param was provided to the SrcSdk factory)
 *
 * @returns SrcSdk object
 */
function SrcSdkFactory() {
  return SrcSdk()
}
