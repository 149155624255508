import licit from '@mc-alberta/licit'

const { types } = licit

export default [
  // TODO: Determine if we want to be more specific
  { key: 'encryptedCard', type: types.string, required: true, onError: 'Must be a valid JWE' },
  // TODO: Determine if we want to be more specific
  // idToken conditional rule is: provided when required (e.g. to enroll a card to one of a range of identified SRC Profiles)
  // We will treat idToken as an optional parameter since we are unable to programmatically determine
  // the srci intent (to correctly enforce the idToken conditional rule)
  { key: 'idToken', type: types.string, onError: 'Must be a valid JWT' },
  // srciTransactionId is optional to comply with "EMVCo-Secure-Remote-Commerce-Specifications-JavaScript-SDK-1.0"
  { key: 'srciTransactionId', type: types.string }
]
