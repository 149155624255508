import type { MaskedCard } from '@mc-alberta/types'
import { DEFAULT_PROGRAM_ID } from '../constants'
import type MethodContext from '../types/MethodContext'

/**
 * Finds the first profile in the state matching the given query.
 */
export function findProfile(
  this: MethodContext,
  {
    maskedCard,
    programId
  }: {
    /** If provided, filters profiles by card ownership. */
    maskedCard?: MaskedCard
    /** If provided, filters profiles by program ID. */
    programId?: string
  }
) {
  // if the issuer requires the user to check out as a guest, no profile can be used
  if (maskedCard?.guestIssuer) return

  // if no query was provided, return nothing
  if (!maskedCard && !programId) return

  return (
    this.state.profiles
      // filter out profiles not belonging to the queried program ID (if specified)
      .filter(({ programUserInterfaceOptions }) => {
        const profileProgramId = programUserInterfaceOptions?.programId || DEFAULT_PROGRAM_ID

        return programId ? profileProgramId === programId : true
      })
      // filter out profiles that don't own the queried card (if specified)
      .filter(({ maskedCards }) => {
        if (!maskedCard) return true

        return maskedCards.some((profileCard) => {
          return profileCard.srcDigitalCardId === maskedCard.srcDigitalCardId
        })
      })
      .shift()
  )
}

/**
 * Caches the card in the global state by adding it to the first SRC profile available (if any)
 * or by adding it to the state's `maskedCards` list.
 */
export function cacheCardInSrcProfile(
  this: MethodContext,
  {
    maskedCard
  }: {
    maskedCard: MaskedCard
  }
) {
  const profile = findProfile.call(this, {
    programId: DEFAULT_PROGRAM_ID
  })

  const cardList =
    profile && !profile.immutable && !maskedCard.guestIssuer
      ? profile.maskedCards
      : this.state.maskedCards

  const existingIndex = cardList.findIndex((existingCard) => {
    return existingCard.srcDigitalCardId === maskedCard.srcDigitalCardId
  })

  if (existingIndex >= 0) {
    cardList[existingIndex] = maskedCard
  } else {
    cardList.push(maskedCard)
  }
}
