import generateState from '../helpers/generateState/index.ts'
import methods from '../methods'

export default function SrcSdkConstructor(state = {}) {
  const sdkState = Object.assign(state, { state: generateState() })
  const sdk = {
    VERSION // 'private', not in spec
  }

  return Object.keys(methods).reduce(
    (sdk, methodName) => ({
      ...sdk,
      [methodName]: methods[methodName].bind(sdkState)
    }),
    sdk
  )
}
