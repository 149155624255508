import State from '../../types/State'
import { generateDefaultConsumerState, generateDefaultSDKState } from './defaultState'

export default function generateInstanceState() {
  return {
    init: function (cache: Partial<State>) {
      Object.assign(this, generateDefaultSDKState(), generateDefaultConsumerState(), cache)
    },
    resetConsumerState: function () {
      Object.assign(this, generateDefaultConsumerState())
    },
    isProduction: true,
    initResolved: false
  } as State
}
