import licit from '@mc-alberta/licit'
import dpaData from './emvco/dpaData'
import generateDpaTransactionOptionsRules from './emvco/generateDpaTransactionOptionsRules'

const { types } = licit

const required = true

export default [
  { key: 'srcInitiatorId', type: types.string, required },
  { key: 'srciDpaId', type: types.string, required },
  // srciTransactionId is optional to comply with "EMVCo-Secure-Remote-Commerce-Specifications-JavaScript-SDK-1.0"
  { key: 'srciTransactionId', type: types.string },
  ...generateDpaTransactionOptionsRules(),
  ...dpaData
]
