/**
 * When REMEMBER_ME compliance resource is present,
 * validate appInstance provided
 *
 * @param {Object} appInstance - Representing a device or app bound to a SRC profile.
 * @param {Object} hasRememberMeResource - ComplianceResource REMEMBER_ME present in complianceSettings
 *
 * @returns {object} - normalizedAppInstance Object
 */
export default function normalizeAppInstance(appInstance, hasRememberMeResource) {
  return hasRememberMeResource ? validateAppInstanceForMRT(appInstance) : appInstance
}

/**
 * Receives appInstance
 * Validates if appInstance object contains required keys
 * Returns appInstance object with userAgent and applicationType keys
 *
 * @param {Object} appInstance - Representing a device or app bound to a SRC profile.
 *
 * @returns {object} - validated appInstance Object
 */
function validateAppInstanceForMRT(originalAppInstance) {
  const DEFAULT_APP_INSTANCE = {
    userAgent: window.navigator.userAgent,
    applicationType: 'BROWSER'
  }

  if (
    !originalAppInstance ||
    Array.isArray(originalAppInstance) ||
    typeof originalAppInstance !== 'object'
  )
    return DEFAULT_APP_INSTANCE

  const requiredAppInstanceKeys = ['userAgent', 'applicationType']
  const isAppInstanceValid = requiredAppInstanceKeys.every((key) =>
    Object.prototype.hasOwnProperty.call(originalAppInstance, key)
  )

  return isAppInstanceValid
    ? originalAppInstance
    : { ...originalAppInstance, ...DEFAULT_APP_INSTANCE }
}
