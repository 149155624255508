import authenticationMethodsLookup from './authenticationMethodsLookup'
import checkout from './checkout'
import deleteRules from './delete'
import enrollCard from './enrollCard'
import getSrcProfile from './getSrcProfile'
import identity from './identity'
import init from './init'

export default {
  init,
  getSrcProfile,
  ...identity,
  enrollCard,
  checkout,
  authenticationMethodsLookup,
  ...deleteRules
}
