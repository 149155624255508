import licit from '@mc-alberta/licit'
import { validAuthenticationReason } from './emvco/enums'
import generateDpaDataRules from './emvco/generateDpaDataRules'
import generateDpaTransactionOptionsRules from './emvco/generateDpaTransactionOptionsRules'

const { types } = licit

const isSrcDigitalCardIdRequired = (params) => !params?.accountReference?.consumerIdentity
const isConsumerIdentityRequired = (params) => !params?.accountReference?.srcDigitalCardId
const isSrcDpaIdRequired = (params) =>
  params?.authenticationContext?.authenticationReasons.includes('TRANSACTION_AUTHENTICATION') &&
  !params?.authenticationContext?.dpaData
const isDpaDataRequired = (params) =>
  params?.authenticationContext?.authenticationReasons.includes('TRANSACTION_AUTHENTICATION') &&
  !params?.authenticationContext?.srcDpaId
const isDpaTransactionOptionsRequired = (params) =>
  params?.authenticationContext?.authenticationReasons.includes('TRANSACTION_AUTHENTICATION')

const isValidAuthenticationReason = (value) => {
  return Array.isArray(value) && value.every((val) => validAuthenticationReason.includes(val))
}

isValidAuthenticationReason.errorDetails = 'Must be an array of valid authentication reasons'

export default [
  { key: 'accountReference', type: types.object, required: true },
  {
    key: 'accountReference.srcDigitalCardId',
    type: types.string,
    required: isSrcDigitalCardIdRequired
  },
  {
    key: 'accountReference.consumerIdentity',
    type: types.object,
    required: isConsumerIdentityRequired
  },
  { key: 'authenticationContext', type: types.object, required: true },
  {
    key: 'authenticationContext.authenticationReasons',
    type: isValidAuthenticationReason,
    required: true
  },
  { key: 'authenticationContext.srcDpaId', type: types.string, required: isSrcDpaIdRequired },
  { key: 'authenticationContext.acquirerMerchantId', type: types.string },
  { key: 'authenticationContext.acquirerBIN', type: types.string },
  { key: 'authenticationContext.merchantName', type: types.string },
  { key: 'srcCorrelationId', type: types.string },
  { key: 'srciTransactionId', type: types.string },
  ...generateDpaTransactionOptionsRules({
    areTheyRequired: isDpaDataRequired,
    parentKey: 'authenticationContext.'
  }),
  ...generateDpaDataRules({
    areTheyRequired: isDpaTransactionOptionsRequired,
    parentKey: 'authenticationContext.'
  })
]
