import { nudetectWidgetDataKeyName } from '../values'

export default function extractNudetectWidgetData(isMastercardSrci, args) {
  let response = {}

  if (!isMastercardSrci) return response

  try {
    const [sdkMethodParams] = args
    const widgetData = sdkMethodParams.__ma_params[nudetectWidgetDataKeyName]
    if (typeof widgetData === 'string') {
      response = { [nudetectWidgetDataKeyName]: widgetData }
    }
  } catch (e) {}

  return response
}
