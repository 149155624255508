import licit from '@mc-alberta/licit'
import extendOptionalValuesToAccept from '../../../helpers/validations/extendOptionalValuesToAccept'

import {
  AddressPreferenceEnum,
  DynamicDataTypeEnum,
  PayloadTypeIndicatorEnum,
  ThreeDsPreferenceEnum,
  TransactionTypeEnum,
  dpaShippingPreferenceEnum,
  validDcfExperiences,
  validIOS4217Codes,
  validIso31661Values
} from './enums'

const { types } = licit

// Shared Enum validators
const PayloadTypeIndicatorEnumValidator =
  types.utils.generateEnumValidator(PayloadTypeIndicatorEnum)
const Iso31661EnumValidator = types.utils.generateEnumValidator(validIso31661Values)

// Conditionality helpers
const threeDSToBePerformedBySRCSystem = (params) =>
  params.dpaTransactionOptions.threeDsPreference === 'ONBEHALF'

// Type helpers
const emptyArray = (value) => Array.isArray(value) && value.length === 0

const Iso31661EnumArrayValidator = (value) => {
  return Array.isArray(value)
    ? value.map((value) => Iso31661EnumValidator(value)).every(Boolean)
    : false
}

const acceptedTypesForOptionalValues = [types.undefined, types.null]

// Rules
export default ({ areTheyRequired = true, parentKey = '' } = {}) =>
  extendOptionalValuesToAccept(...acceptedTypesForOptionalValues)([
    {
      key: `${parentKey}dpaTransactionOptions`,
      type: types.object,
      // dpaTransactionOptions is a required param for init per EMVCo SRC Web Client SDK 1.0 (TA Review)
      // dpaTransactionOptions is an optional param for init per EMVCo SRC Web Client SDK JavaScript SDK 1.0 (Final 1.0 version)
      // TODO for migration to the final EMVCo SRC Web Client SDK JavaScript SDK 1.0 (or 1.1):  Make dpaTransactionOptions
      // optional in init and conditional in checkouto;p
      required: areTheyRequired
    },
    {
      key: `${parentKey}dpaTransactionOptions.consumerNationalIdentifierRequested`,
      // MC custom field for Brazil National ID
      // Field is being proposed for EMVCO 1.2----------Z.
      type: types.boolean
    },
    {
      key: `${parentKey}dpaTransactionOptions.transactionAmount`,
      type: types.object,
      required: threeDSToBePerformedBySRCSystem
    },
    {
      key: `${parentKey}dpaTransactionOptions.transactionAmount.transactionAmount`,
      type: types.number,
      required: true
    },
    {
      key: `${parentKey}dpaTransactionOptions.transactionAmount.transactionCurrencyCode`,
      type: types.utils.generateEnumValidator(validIOS4217Codes),
      required: true
    },
    {
      key: `${parentKey}dpaTransactionOptions.transactionType`,
      type: types.utils.generateEnumValidator(TransactionTypeEnum)
    },
    {
      key: `${parentKey}dpaTransactionOptions.dpaBillingPreference`,
      type: types.utils.generateEnumValidator(AddressPreferenceEnum)
    },
    {
      key: `${parentKey}dpaTransactionOptions.dpaAcceptedBillingCountries`,
      type: [Iso31661EnumArrayValidator, emptyArray],
      onError: `Must be an array of country codes in ISO 3166-1 alpha-2 format`
    },
    {
      key: `${parentKey}dpaTransactionOptions.dpaShippingPreference`,
      type: types.utils.generateEnumValidator(dpaShippingPreferenceEnum)
    },
    {
      key: `${parentKey}dpaTransactionOptions.dpaAcceptedShippingCountries`,
      type: [Iso31661EnumArrayValidator, emptyArray],
      onError: `Must be an array of country codes in ISO 3166-1 alpha-2 format`
    },
    {
      key: `${parentKey}dpaTransactionOptions.consumerEmailAddressRequested`,
      type: types.boolean
    },
    { key: `${parentKey}dpaTransactionOptions.consumerNameRequested`, type: types.boolean },
    {
      key: `${parentKey}dpaTransactionOptions.consumerPhoneNumberRequested`,
      type: types.boolean
    },
    {
      key: `${parentKey}dpaTransactionOptions.merchantCategoryCode`,
      type: types.string,
      strictOptionality: true
    },
    {
      key: `${parentKey}dpaTransactionOptions.merchantCountryCode`,
      type: Iso31661EnumValidator
    },
    { key: `${parentKey}dpaTransactionOptions.merchantOrderId`, type: types.string },
    {
      key: `${parentKey}dpaTransactionOptions.threeDsPreference`,
      type: types.utils.generateEnumValidator(ThreeDsPreferenceEnum),
      required: threeDSToBePerformedBySRCSystem
    },
    {
      key: `${parentKey}dpaTransactionOptions.threeDsInputData`,
      type: types.object,
      required: threeDSToBePerformedBySRCSystem
    },
    {
      key: `${parentKey}dpaTransactionOptions.threeDsInputData.requestorId`,
      type: types.string,
      required: true
    },
    {
      key: `${parentKey}dpaTransactionOptions.threeDsInputData.acquirerId`,
      type: types.string,
      required: true
    },
    {
      key: `${parentKey}dpaTransactionOptions.threeDsInputData.acquirerMid`,
      type: types.string,
      required: true
    },
    {
      key: `${parentKey}dpaTransactionOptions.srcTokenRequestData`,
      type: () => {
        // value is yet to be TBC in the EMVCO 1.0 API TA Review
        // accept all values until the value is formally defined
        return true
      }
    },
    {
      key: `${parentKey}dpaTransactionOptions.payloadTypeIndicatorCheckout`,
      type: PayloadTypeIndicatorEnumValidator
    },
    {
      key: `${parentKey}dpaTransactionOptions.payloadTypeIndicatorPayload`,
      type: PayloadTypeIndicatorEnumValidator
    },
    { key: `${parentKey}dpaTransactionOptions.recipientKeyIdCheckout`, type: types.string },
    { key: `${parentKey}dpaTransactionOptions.recipientKeyIdPayload`, type: types.string },
    { key: `${parentKey}dpaTransactionOptions.paymentOptions`, type: types.object },
    {
      key: `${parentKey}dpaTransactionOptions.paymentOptions.dpaDynamicDataTtlMinutes`,
      type: types.string, // TODO: Determine expected value
      onError: `Must be a string indicating an int32 value`
    },
    {
      key: `${parentKey}dpaTransactionOptions.paymentOptions.dynamicDataType`,
      type: types.utils.generateEnumValidator(DynamicDataTypeEnum),
      // Remove empty string enum (indicating fpan transaction) from error message
      // to avoid advertising the custom fpan option
      onError: types.utils.generateEnumValidator(DynamicDataTypeEnum.filter(Boolean)).errorDetails,
      strictOptionality: true
    }, // TODO: Should this work as an empty string
    { key: `${parentKey}dpaTransactionOptions.serviceId`, type: types.string },
    // Temporarily handle dpaLocale validation separately to log all
    // occurrences of invalid dpaLocales
    // We are currently logging 4% of failed validations
    // {
    //   key: `dpaTransactionOptions.dpaLocale`,
    //   type: value => RegExp(`^[a-z]{2}[_][A-Z]{2}$`).test(value),
    //   onError: `ISO language_country pair. E.g. ‘en_US’, ‘fr_CA’`,
    //   strictOptionality: true
    // },
    // { key: `networkSpecificInputData`, type: () => true }, TODO: Implement validation
    { key: `${parentKey}dpaTransactionOptions.isGuestCheckout`, type: types.boolean }, // TODO: check spelling
    {
      key: `${parentKey}dpaTransactionOptions.customInputData`,
      type: types.object,
      strictOptionality: true
    },
    {
      key: `${parentKey}dpaTransactionOptions.customInputData['com.mastercard.dcfExperience']`,
      type: types.utils.generateEnumValidator(validDcfExperiences),
      strictOptionality: true
    }
  ])
