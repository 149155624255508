import licit from '@mc-alberta/licit'
import extendOptionalValuesToAccept from '../../../helpers/validations/extendOptionalValuesToAccept'

const { types } = licit

const ApplicationTypeEnum = ['Browser', 'Application', 'IoT', 'Other']

const acceptedTypesForOptionalValues = [types.undefined, types.null]

export default extendOptionalValuesToAccept(...acceptedTypesForOptionalValues)([
  { key: 'dpaData', type: types.object },
  { key: 'dpaData.dpaPresentationName', type: types.string },
  { key: 'dpaData.dpaAddress', type: types.object },
  { key: 'dpaData.dpaAddress.addressId', type: types.string },
  { key: 'dpaData.dpaAddress.name', type: types.string },
  { key: 'dpaData.dpaAddress.line1', type: types.string },
  { key: 'dpaData.dpaAddress.line2', type: types.string },
  { key: 'dpaData.dpaAddress.line3', type: types.string },
  { key: 'dpaData.dpaAddress.city', type: types.string },
  { key: 'dpaData.dpaAddress.state', type: types.string },
  { key: 'dpaData.dpaAddress.countryCode', type: types.string },
  { key: 'dpaData.dpaAddress.zip', type: types.string },
  { key: 'dpaData.dpaAddress.createTime', type: types.string },
  { key: 'dpaData.dpaAddress.lastUsedTime', type: types.string },
  // while dpaData.dpaName is required per EMVCo SRC API 1.0 (TA Review)
  // we consider dpaData.dpaName optional per agreement with Visa
  { key: 'dpaData.dpaName', type: types.string },
  { key: 'dpaData.dpaEmailAddress', type: types.string },
  { key: 'dpaData.dpaPhoneNumber', type: types.string },
  { key: 'dpaData.dpaLogoUri', type: types.string },
  { key: 'dpaData.dpaSupportEmailAddress', type: types.string },
  { key: 'dpaData.dpaSupportPhoneNumber', type: types.string },
  { key: 'dpaData.dpaSupportUri', type: types.string },
  { key: 'dpaData.dpaUri', type: types.string },
  { key: 'dpaData.applicationType', type: types.utils.generateEnumValidator(ApplicationTypeEnum) }
])
