import { lumber } from '@mc-alberta/lumber'

const methodsToOverride = ['info', 'warn', 'debug', 'error']

export default methodsToOverride.reduce((remoteLogger, methodName) => {
  const lumberMethod = lumber[methodName].bind(lumber)
  remoteLogger[methodName] = function (param, state) {
    try {
      return lumberMethod(addInstanceStateToParam(param, state))
    } catch (e) {
      return lumberMethod(param)
    }
  }
  return lumber
}, lumber)

function addInstanceState(state) {
  const srcCorrelationId = state.headers['SRC-Correlation-Id']
  const srciTransactionId = state.headers['SRCI-Transaction-Id']
  const traceId = state.headers['x-src-trace-id']

  return {
    ...(srcCorrelationId ? { srcCorrelationId } : {}),
    ...(srciTransactionId ? { srciTransactionId } : {}),
    ...(traceId ? { traceId } : {})
  }
}

function addInstanceStateToParam(param, state) {
  function addInstanceStateToPayload(payload) {
    return {
      ...payload,
      details: {
        ...payload.details,
        ...addInstanceState(state)
      }
    }
  }

  return Array.isArray(param)
    ? param.map(addInstanceStateToPayload)
    : addInstanceStateToPayload(param)
}
