import licit from '@mc-alberta/licit'

const { types } = licit

const ConsumerIdentityTypeEnum = ['EMAIL_ADDRESS', 'MOBILE_PHONE_NUMBER']

const required = true

const identityLookup = [
  { key: 'consumerIdentity', type: types.object, required: true },
  { key: 'consumerIdentity.identityProvider', type: (value) => value === 'SRC' },
  {
    key: 'consumerIdentity.identityType',
    type: types.utils.generateEnumValidator(ConsumerIdentityTypeEnum),
    required
  },
  { key: 'consumerIdentity.identityValue', type: types.string, required }
]

const initiateIdentityValidation = [{ key: 'requestedValidationChannelId', type: types.string }]

const completeIdentityValidation = [{ key: 'validationData', type: types.string, required }]

export default {
  identityLookup,
  initiateIdentityValidation,
  completeIdentityValidation
}
