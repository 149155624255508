import messenger from '@mc-alberta/messenger'

const LOADING = 'LOADING'
const LOADED = 'LOADED'

const iframeState = {
  reference: async () => null,
  loadStatus: null
}

export default async function iframeReference(srcDomain, nudetectId) {
  let iframePromise, handleIframeLoading

  switch (iframeState.loadStatus) {
    case LOADED:
    case LOADING:
      return iframeState.reference()
    default:
      iframeState.loadStatus = LOADING
      iframePromise = bootstrapIframe(srcDomain, nudetectId)
      handleIframeLoading = async () => {
        const iframe = await iframePromise
        const iFrameRefHandler = async () => iframe
        iframeState.reference = iFrameRefHandler
        iframeState.loadStatus = LOADED
        return iframe
      }
      iframeState.reference = handleIframeLoading
      return iframeState.reference()
  }
}

async function bootstrapIframe(host) {
  // only append once, but can be reloaded
  const el = document.createElement('iframe')

  // add mastercard.proxyinit listener
  // mastercard.proxyinit post message signals iframe successfully
  // initiated. This listener is awaited after the iframe is created
  // The listener is created prior to iframe creation to reduce the risk
  // of a race condition between the iframe initializing and the sdk
  // adding the listener. The drawback of adding the listener prior to
  // iframe creation is that we cannot validate sender (meaning the window who
  // sent the message)
  const proxyInitEventListener = messenger.once('mastercard.proxyinit', {
    domain: host
  })

  // this will be a path on SRC host to allow for non-CORS calls all
  // enviornments must support the same path,
  // only host is protocol/host/port is configurable via init({host})
  const version = VERSION
  const communicatorFrameUrl = `${host}/sdk/communicator-frame.${version}.html`

  el.style.border = '0'
  el.style.display = 'none'
  el.style.height = '0'
  el.style.width = '0'
  el.setAttribute('src', communicatorFrameUrl)

  iframeState.reference = el
  document.body.appendChild(el)

  // in the event iframe.contentWindow is not available
  // (possibly due to constraint async resources) await
  // the loading of iframe.contentWindow
  if (!el.contentWindow) {
    // if iframe.contentWindow becomes available between
    // the `if` statement clause and `if` statement body
    // forIframeToLoad will never resolve
    await forIframeToLoad(el)
  }

  // await for mastercard.proxyinit listener to resolve (i.e., wait for proxy
  // iframe to confirm initiation)
  await proxyInitEventListener

  // nudetect initialization is separate from iframe loading to
  // avoid a refactor in the iframe loading and awaiting routing
  // see the comments above the awaits for forIframeToLoad and
  // proxyInitEventListener

  // Temporarily disable nudetect initialization
  // until feature is fully advertised
  // await messenger.send(
  //   el.contentWindow,
  //   'mastercard.bootstrap.nudetect',
  //   { nudetectId },
  //   { domain: host }
  // ).catch(() => {
  //   // TODO: Remote log error
  // })

  iframeState.reference = async () => el
  iframeState.loadStatus = LOADED
  return iframeState.reference()
}

// helper function to await to the loading
// of iframe.contentWindow
export function forIframeToLoad(iframe) {
  return new Promise((resolve) => {
    iframe.addEventListener('load', resolve)
  })
}
