import Magnumkpi from '@mc-alberta/magnumkpi'
import logToKPI from '../utils/kpiLogger'

const { STARTED, COMPLETED } = Magnumkpi.STATES

export default function DecorateWithKPILogging(sdkMethods) {
  return Object.keys(sdkMethods).reduce(
    (wrappedMethods, methodName) => ({
      ...wrappedMethods,
      [methodName]: includeLogging(sdkMethods[methodName], methodName)
    }),
    {}
  )
}

function includeLogging(fn, methodName) {
  if (typeof fn !== 'function') {
    throw new Error('Invalid argument: fn must be a function')
  }

  return async function (...params) {
    try {
      logToKPI({
        kpiLogger: this.kpiLogger,
        methodName,
        payload: params,
        state: this.state,
        status: STARTED
      })

      const results = await fn.apply(this, params)

      logToKPI({
        kpiLogger: this.kpiLogger,
        methodName,
        result: results,
        payload: params,
        state: this.state,
        status: COMPLETED
      })

      return results
    } catch (error) {
      logToKPI({
        kpiLogger: this.kpiLogger,
        methodName,
        result: undefined,
        payload: params,
        state: this.state,
        error
      })
      throw error
    }
  }
}
