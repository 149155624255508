import licit from '@mc-alberta/licit'
import events from '../../../events.json'
import remoteLogger from '../../helpers/remoteLogging'
import paramRules from '../../validations/rules'

export const validateParams = ({ params, methodName } = {}) => {
  if (typeof params === 'undefined') {
    params = {} // provide a valid licit.validateObject value
  }

  const rules = paramRules[methodName]
  const rulesArePresent = Array.isArray(rules)

  if (rulesArePresent) {
    const validationResult = licit.validateObject(params, rules)

    return validationResult
  } else {
    throw new TypeError(
      `invalid validateObjectParams for method :${methodName}. params type: ${typeof params}. rules type: ${typeof rules}`
    )
  }
}

export const logValidationError = ({ methodName, details } = {}, state) => {
  let merchantDomain
  try {
    details =
      details instanceof Error
        ? JSON.stringify(details, Object.getOwnPropertyNames(details))
        : JSON.stringify(details)
    merchantDomain = window.location.origin
  } catch (e) {
    details = 'validateObject failed during execution. Unable to stringify exception'
  }

  const validationError = details
  remoteLogger.error(
    {
      ...events.errors.SRCSDK_MASTERCARD_PARAM_VALIDATION_ERROR,
      details: {
        merchantDomain,
        validationError,
        ...(methodName ? { methodName } : null)
      }
    },
    state
  )
}
