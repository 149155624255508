import licit from '@mc-alberta/licit'
import extendOptionalValuesToAccept from '../../../helpers/validations/extendOptionalValuesToAccept'

const { types } = licit

const isCardSecurityCodeRequired = (params) =>
  params.authenticationMethod.authenticationMethodType === 'CSC_VALIDATION'

const isOtpValueRequired = (params) =>
  ['SMS_OTP', 'EMAIL_OTP', 'EMAIL_OTP'].includes(
    params.authenticationMethod.authenticationMethodType
  )

const isBillingAddressRequired = (params) =>
  params.authenticationMethod.authenticationMethodType === 'ADDRESS_VERIFICATION'

const isSpcRequired = (params) => params.authenticationMethod.authenticationMethodType === 'SPC'

const isThreeDsDataRequired = (params) =>
  params.authenticationMethod.authenticationMethodType === '3DS'

const acceptedTypesForOptionalValues = [types.undefined, types.null]

export default ({ areTheyRequired = true } = {}) =>
  extendOptionalValuesToAccept(...acceptedTypesForOptionalValues)([
    {
      key: 'authenticationMethod',
      type: types.object,
      // authenticationMethod is an optional param for checkout per EMVCo SRC Web Client SDK JavaScript SDK 1.3
      required: areTheyRequired
    },
    {
      key: 'authenticationMethod.authenticationMethodType',
      type: types.string,
      required: true
    },
    {
      key: 'authenticationMethod.authenticationSubject',
      type: types.string,
      required: true
    },
    {
      key: 'authenticationMethod.uriData',
      type: types.object
    },
    {
      key: 'authenticationMethod.uriData.uri',
      type: types.string,
      required: true
    },
    {
      key: 'authenticationMethod.uriData.uriType',
      type: types.string,
      required: true
    },
    {
      key: 'authenticationMethod.authenticationCredentialReference',
      type: types.string
    },
    {
      key: 'authenticationMethod.methodAttributes',
      type: types.object
    },
    {
      key: 'authenticationMethod.methodAttributes.cardSecurityCode',
      type: types.string,
      required: isCardSecurityCodeRequired
    },
    {
      key: 'authenticationMethod.methodAttributes.otpValue',
      type: types.string,
      required: isOtpValueRequired
    },
    {
      key: 'authenticationMethod.methodAttributes.billingAddress',
      type: types.object,
      required: isBillingAddressRequired
    },
    {
      key: 'authenticationMethod.methodAttributes.spcRequest',
      type: types.object,
      required: isSpcRequired
    },
    {
      key: 'authenticationMethod.methodAttributes.spcResponse',
      type: types.object,
      required: isSpcRequired
    },
    {
      key: 'authenticationMethod.methodAttributes.threeDsData',
      type: types.object,
      required: isThreeDsDataRequired
    }
  ])
