import SRCError from '../SRCError'

const invalidParameterError = {
  error: {
    status: 400,
    reason: 'INVALID_PARAMETER',
    message: `SDK was not successfully initialized`
  }
}
/**
 * Decorates SDK methods with a check to verify if init successfully resolved
 *
 * @param {Object} wrapper object containing all SDK methods except init
 *
 * @returns {Object} wrapper with decorated SDK methods
 */
export default function DecorateWithSDKInitCheck(sdkMethods) {
  return Object.keys(sdkMethods).reduce(
    (methodsWrapper, methodName) => ({
      ...methodsWrapper,
      [methodName]: verifySDKWasSuccessfullyInitialized(sdkMethods[methodName])
    }),
    {}
  )
}

// helper function
function verifySDKWasSuccessfullyInitialized(sdkMethod) {
  return async function (...params) {
    if (!this.state.initResolved) throw new SRCError(invalidParameterError)

    return sdkMethod.apply(this, params)
  }
}
