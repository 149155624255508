import licit from '@mc-alberta/licit'

const { types } = licit

const anArrayOfStrings = (value) => {
  return Array.isArray(value)
    ? // TODO: Verify all strings must be valid
      value.every((token) => types.string(token) === true)
    : false
}
anArrayOfStrings.errorDetails = 'Must be an array of tokens'

export default [{ key: 'idTokens', type: anArrayOfStrings }]
