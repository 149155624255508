import licit from '@mc-alberta/licit'
import { logValidationError, validateParams } from '../helpers/validations/validationsUtils'
import dpaLocaleSchema from '../validations/rules/emvco/dpaLocale'
import pick from './pick'

const utils = {
  pick,
  getOrigin(url) {
    // TODO: Refactor to increase the resilience of the fallback origin value
    // getOrigin is used in `init` and `checkout`. Given the current SRCi
    // configuration the DEFAULT_ORIGIN fallback should work in both cases
    // In the event DCF were to change their URL we should replace the
    // DEFAULT_ORIGIN value with some form of resilient fallback (such as
    // await until document.body is available to ensure we can add
    // the temporary iframe)
    const DEFAULT_ORIGIN = 'https://src.mastercard.com'
    let origin = DEFAULT_ORIGIN
    try {
      const iframe = document.createElement('iframe')
      document.body.appendChild(iframe)
      // wrap contentWindow.URL(url).origin request
      // in a try/catch to ensure we still remove the
      // temporary iframe if anything goes wrong
      try {
        origin = new iframe.contentWindow.URL(url).origin
      } catch (e) {}
      document.body.removeChild(iframe)
    } catch (e) {}
    return origin
  },
  // do not destructure in the function definition to avoid
  // unhandled exceptions
  // eslint-disable-next-line complexity
  performNonBlockingValidations(validationParams, state) {
    let methodName
    try {
      methodName = validationParams.methodName
      const validationResult = validateParams(validationParams)

      try {
        if (this.debugModeIsOn()) {
          console.warn(`Validation results for ${methodName}`, validationResult)
        }

        // Remote log 1 of every 10000 params validation failures
        // Do not increase % before reviewing events logged prior to 8/18/2021
        // Percentage increases above 4% of total param validation failures must be approved by Bizops
        if (!validationResult.valid && Math.random() < 0.0001) {
          // avoid logging valid rules
          logValidationError(
            { details: [...validationResult.details.invalidRules], methodName },
            state
          )
        }
      } catch (e) {}

      return validationResult
    } catch (error) {
      try {
        // temporarily disable remote logging for validations
        // logValidationError({ details: error, methodName })
        if (this.debugModeIsOn()) {
          console.warn(`The Validation logic for ${methodName} failed. The exception is :`, error)
        }
      } catch (e) {}

      const fallBackValidationResponse = {
        details: {
          invalidRules: [],
          validRules: [
            {
              details: 'Hard coded validation response from performNonBlockingValidations',
              key: '*'
            }
          ]
        },
        valid: true
      }

      return fallBackValidationResponse
    }
  },
  debugModeIsOn() {
    return Boolean(window.MC_SRC_DEBUG)
  },
  paramsIsAnObject(params) {
    // maintain until blocking validations are enabled
    return Object.prototype.toString.call(params) === '[object Object]'
  },
  // TODO: remove once licit validations are enforced
  isDpaLocaleInvalid(dpaTransactionOptions) {
    const dpaTransactionOptionsWereProvided = licit.isAnObject(dpaTransactionOptions)

    if (dpaTransactionOptionsWereProvided) {
      const result = licit.validateObject(dpaTransactionOptions, dpaLocaleSchema)
      const invalidRules = result.details.invalidRules

      if (invalidRules.length) {
        logDpaLocalValidationError(invalidRules, dpaTransactionOptions.dpaLocale)
      }

      return !result.valid
    } else {
      return false
    }
  },
  // Get country code form locale i.e. from 'en_US' = 'US'
  getCountryCode(locale) {
    const codes = locale.split('_')
    return codes[1].toUpperCase()
  }
}

export default utils

// helper function to validate locale
function logDpaLocalValidationError(errors, dpaLocaleValue) {
  let dpaLocale = 'Unable to serialize dpaLocale'
  try {
    dpaLocale = `${dpaLocaleValue}`
  } catch (e) {}

  logValidationError({
    details: {
      invalidRules: errors,
      dpaLocale
    }
  })
}
