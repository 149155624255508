import licit from '@mc-alberta/licit'

export default function normalizeDpaLocale(dpaTransactionOptions) {
  if (!licit.isAnObject(dpaTransactionOptions)) return dpaTransactionOptions
  let response = dpaTransactionOptions

  try {
    const clonedResponse = JSON.parse(JSON.stringify(dpaTransactionOptions))
    const providedDpaLocale = clonedResponse.dpaLocale
    const isValidFormat = RegExp(`^[a-zA-Z]{2}[_][a-zA-Z]{2}$`).test(providedDpaLocale)
    if (isValidFormat) {
      const language = providedDpaLocale.slice(0, 2).toLowerCase()
      const country = providedDpaLocale.slice(3, 5).toUpperCase()
      clonedResponse.dpaLocale = `${language}_${country}`
      response = clonedResponse
    }
  } catch (e) {
    // The responsibility of logging invalid dpaLocales is delegated
    // to the licit validations
  }

  return response
}
