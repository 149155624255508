import licit from '@mc-alberta/licit'
import complianceSettings from './emvco/complianceSettings'
import generateAuthenticationMethodRules from './emvco/generateAuthenticationMethodRules'
import generateDpaTransactionOptionsRules from './emvco/generateDpaTransactionOptionsRules'

const { types } = licit

const SrciActionCodeEnum = ['NEW_USER', 'AUTH_FAILED', 'AUTH_SKIPPED']

const payloadTypeIndicatorEnum = ['SUMMARY', 'FULL']

const validWindowRef = (winRef) => {
  return Boolean(winRef.opener) || Boolean(winRef.parent)
}
validWindowRef.onError = 'Must be a valid window reference'

const srcDigitalCardIdOrEncryptedCardProvided = (params) => {
  const srcDigitalCardIdOrEncryptedCardProvided =
    Object.prototype.hasOwnProperty.call(params, 'srcDigitalCardId') ||
    Object.prototype.hasOwnProperty.call(params, 'encryptedCard')
  return !srcDigitalCardIdOrEncryptedCardProvided
}

export default [
  {
    key: 'srcDigitalCardId',
    type: types.string,
    required: srcDigitalCardIdOrEncryptedCardProvided,
    onError:
      'Must be a valid srcDigitalCardId. srcDigitalCardId is a required value unless encryptedCard is provided'
  },
  {
    key: 'encryptedCard',
    type: types.string,
    required: srcDigitalCardIdOrEncryptedCardProvided,
    onError:
      'Must be a valid JWE. encryptedCard is a required value unless srcDigitalCardId is provided'
  },
  { key: 'idToken', type: types.string, onError: 'Must be a valid JWT' },
  // mark DpaTransactionOptions as optional using `areTheyRequired: false`
  ...generateDpaTransactionOptionsRules({ areTheyRequired: false }),
  // TODO. Add assuranceData
  {
    key: 'srciActionCode',
    type: types.utils.generateEnumValidator(SrciActionCodeEnum)
  },
  // windowRef is a conditional param per EMVCo SRC Web Client SDK 1.0 (TA Review) but no criteria is provided.
  // windowRef is an optional param in the EMVCo SRC Web Client SDK JavaScript SDK 1.0 (Final 1.0 version). We will
  // treat windowRef as optional param
  { key: 'windowRef', type: validWindowRef },
  // srciTransactionId is optional to comply with "EMVCo-Secure-Remote-Commerce-Specifications-JavaScript-SDK-1.0"
  { key: 'srciTransactionId', type: types.string },
  // payloadTypeIndicatorCheckout is optional, currently support 'SUMMARY' and 'FULL'
  {
    key: 'payloadTypeIndicatorCheckout',
    type: types.utils.generateEnumValidator(payloadTypeIndicatorEnum)
  },
  // recipientIdCheckout is optional
  { key: 'recipientIdCheckout', type: types.string },
  // profileOptOut is optional
  { key: 'profileOptOut', type: types.boolean },
  // authenticationMethod is optional
  ...generateAuthenticationMethodRules({ areTheyRequired: false }),
  // { key: 'consumer', type: TBD based on Consumer EMVCO object } // per product direction (S1999691) consumer should not be validated
  // complianceSettings is optional to comply with "EMVCo-Secure-Remote-Commerce-Specifications-API1.1"
  ...complianceSettings
]
