import type { ProgramUserInterfaceOptions } from './types/ProgramUserInterfaceOptions'

/** The default program ID. A profile enrolled in this program is mutable. */
export const DEFAULT_PROGRAM_ID = 'SRC'

/**
 * The Manual PAN Tokenization (MPT) program ID.
 * Used for tokenizing cards when `profileOptOut` is true.
 */
export const MPT_PROGRAM_ID =
  'MANUAL_PAN_ENTRY_TOKENIZATION#MANUAL_PAN_ENTRY_TOKENIZATION-CLOUDTOKENS#01'

/** Default program-level configuration for a profile. */
export const DEFAULT_PROGRAM_OPTIONS: ProgramUserInterfaceOptions = {
  programId: DEFAULT_PROGRAM_ID,
  profileUpdateAllowed: true,
  profileUpdateConsentRequired: false,
  profileTncManagedBySRC: true
}
