import licit from '@mc-alberta/licit'
import extendOptionalValuesToAccept from '../../../helpers/validations/extendOptionalValuesToAccept'

const { types } = licit

const ApplicationTypeEnum = ['Browser', 'Application', 'IoT', 'Other']

const acceptedTypesForOptionalValues = [types.undefined, types.null]

export default ({ areTheyRequired = true, parentKey = '' } = {}) =>
  extendOptionalValuesToAccept(...acceptedTypesForOptionalValues)([
    { key: `${parentKey}dpaData`, type: types.object, required: areTheyRequired },
    { key: `${parentKey}dpaData.dpaPresentationName`, type: types.string },
    { key: `${parentKey}dpaData.dpaAddress`, type: types.object },
    { key: `${parentKey}dpaData.dpaAddress.addressId`, type: types.string },
    { key: `${parentKey}dpaData.dpaAddress.name`, type: types.string },
    { key: `${parentKey}dpaData.dpaAddress.line1`, type: types.string },
    { key: `${parentKey}dpaData.dpaAddress.line2`, type: types.string },
    { key: `${parentKey}dpaData.dpaAddress.line3`, type: types.string },
    { key: `${parentKey}dpaData.dpaAddress.city`, type: types.string },
    { key: `${parentKey}dpaData.dpaAddress.state`, type: types.string },
    { key: `${parentKey}dpaData.dpaAddress.countryCode`, type: types.string },
    { key: `${parentKey}dpaData.dpaAddress.zip`, type: types.string },
    { key: `${parentKey}dpaData.dpaAddress.createTime`, type: types.string },
    { key: `${parentKey}dpaData.dpaAddress.lastUsedTime`, type: types.string },
    // while dpaData.dpaName is required per EMVCo SRC API 1.0 (TA Review)
    // we consider dpaData.dpaName optional per agreement with Visa
    { key: `${parentKey}dpaData.dpaName`, type: types.string },
    { key: `${parentKey}dpaData.dpaEmailAddress`, type: types.string },
    { key: `${parentKey}dpaData.dpaPhoneNumber`, type: types.string },
    { key: `${parentKey}dpaData.dpaLogoUri`, type: types.string },
    { key: `${parentKey}dpaData.dpaSupportEmailAddress`, type: types.string },
    { key: `${parentKey}dpaData.dpaSupportPhoneNumber`, type: types.string },
    { key: `${parentKey}dpaData.dpaSupportUri`, type: types.string },
    { key: `${parentKey}dpaData.dpaUri`, type: types.string },
    {
      key: `${parentKey}dpaData.applicationType`,
      type: types.utils.generateEnumValidator(ApplicationTypeEnum)
    }
  ])
