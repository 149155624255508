import nudetectWidgetData from '../helpers/extractNudetectWidgetData'
import post from '../post'
import transform from '../transform'
import transformComplianceSettingsToEmvcoPointNine from '../transform/complianceSettingsEmvcoPointNine'
import utils from '../utils'
import normalizeAppInstance from '../utils/normalizeAppInstance'
import validate from '../validate'

export async function identityLookup({
  consumerIdentity // required
} = {}) {
  const { state } = this

  utils.performNonBlockingValidations(
    {
      params: arguments[0],
      methodName: 'identityLookup'
    },
    state
  )
  validate.params({ consumerIdentity })
  validate.identity({ consumerIdentity })

  const message = {
    method: 'post',
    url: 'identities/lookup',
    headers: { ...state.headers },
    data: {
      // type is nolonger 'EMAIL', its 'EMAIL_ADDRESS'
      consumerIdentity: transform.identity.consumerIdentity.request(consumerIdentity)
    },
    ...nudetectWidgetData(state.isMastercardSrci, arguments)
  }
  const result = await post.send(message, state)
  validate.response(result, { 400: 'INVALID_PARAMETER' })
  // NOTE: FRAUD error will never be returned

  state.idLookupSessionId = result.idLookupSessionId
  // this is the untransformed consumer identity
  state.consumerIdentity = consumerIdentity

  return {
    consumerPresent: result.consumerPresent, // required
    ...(result.lastUsedCardTimestamp && { lastUsedCardTimestamp: result.lastUsedCardTimestamp })
  }
}

// NOTE: does not yet support requestedValidationChannelId
export async function initiateIdentityValidation({
  requestedValidationChannelId // optional
} = {}) {
  const { state } = this

  utils.performNonBlockingValidations(
    {
      params: arguments[0],
      methodName: 'initiateIdentityValidation'
    },
    state
  )
  const message = {
    method: 'post',
    url: 'identities/verification',
    headers: { ...state.headers },
    data: {
      idLookupSessionId: state.idLookupSessionId
    }
  }

  // if this is the second time initiateIdentityValidation is invoked
  // supportedValidationChannels will be available and the SRCi can
  // pick the validation channel (via requestedValidationChannelId param)
  const srciCanSelectValidationChannel = Boolean(state.supportedValidationChannels.length)

  if (srciCanSelectValidationChannel && requestedValidationChannelId) {
    const requestedValidationChannel = state.supportedValidationChannels.find(
      ({ identityType }) => identityType === requestedValidationChannelId
    )

    if (requestedValidationChannel) {
      message.data.requestedValidationChannel = transform.identity.consumerIdentity.request(
        requestedValidationChannel
      )
    }
  }

  const result = await post.send(message, state)
  validate.response(result, { 400: 'INVALID_PARAMETER', 404: 'INVALID_PARAMETER' })
  // TODO: handle error reponse
  // UNRECOGNIZED_CONSUMER_ID - not sure this possible, see above

  // NOTE: errors OTP_SEND_FAILED and RETRIES_EXCEEDED will never be returned

  state.idVerificationId = result.idVerificationId

  const response = transform.identity.initiateIdentityValidation.response(
    utils.pick(
      result,
      'maskedValidationChannel', // required
      'validationMessage', // optional
      'supportedValidationChannels' // should always be returned by MC SRC APIs
    )
  )

  // cache supportedValidationChannels to allow user to select otp
  // upon re-invoking initiateIdentityValidation
  if (Array.isArray(response.supportedValidationChannels)) {
    state.supportedValidationChannels = response.supportedValidationChannels
  }

  return response
}

export async function completeIdentityValidation({
  validationData, // required
  complianceSettings, // optional
  appInstance // optional
} = {}) {
  const { state } = this

  utils.performNonBlockingValidations(
    {
      params: arguments[0],
      methodName: 'completeIdentityValidation'
    },
    state
  )
  validate.params({ validationData })

  const emvcoPointNineComplianceSettings =
    transformComplianceSettingsToEmvcoPointNine(complianceSettings)
  const normalizedAppInstance = normalizeAppInstance(
    appInstance,
    emvcoPointNineComplianceSettings?.rememberMe
  )

  if (normalizedAppInstance) {
    state.appInstance = normalizedAppInstance
  }

  const message = {
    method: 'post',
    url: 'identities/validation',
    headers: { ...state.headers },
    data: {
      idVerificationId: state.idVerificationId,
      validationData,
      ...(complianceSettings && { complianceSettings: emvcoPointNineComplianceSettings }),
      ...(normalizedAppInstance && { appInstance: normalizedAppInstance })
    },
    ...nudetectWidgetData(state.isMastercardSrci, arguments)
  }
  const result = await post.send(message, state)
  validate.response(result, { 400: 'INVALID_PARAMETER' }, 'COMPLETE_IDENTITY_VALIDATION')
  // TODO: handle error reponse
  // CODE_INVALID, currently this is just 400/ACCT_INACCESSIBLE

  // Note: error RETRIES_EXCEEDED will never be returned

  // if we OTP the user, we'll have a fully verified MC token
  // so we can also use this as a default to pass to DCF
  if (result.authorization) {
    state.idToken = result.authorization
  }

  return {
    idToken: result.authorization,
    ...(state.headers['SRC-Recognition-Token'] && {
      recognitionToken: state.headers['SRC-Recognition-Token']
    })
  }
}
