import Magnumkpi from '@mc-alberta/magnumkpi'
import { PayloadRequested } from '@mc-alberta/types'
import jwtDecode from 'jwt-decode'
import { DEFAULT_PROGRAM_ID, MPT_PROGRAM_ID } from '../constants'
import { KPI_METHODS, KPI_METHOD_DATA } from './constants'

const { COMPLETED, FAILED } = Magnumkpi.STATES
const { INIT, CHECKOUT, GET_SRC_PROFILE, AUTHENTICATION_METHODS_LOOKUP } = KPI_METHODS

let initPayload = {}
let dcfName

export default function logToKPI(log) {
  try {
    attemptLogToKPI(log)
  } catch (error) {}
}

/* eslint complexity: [2, 16] */
function attemptLogToKPI({
  kpiLogger,
  methodName,
  result,
  payload = {},
  state = {},
  error,
  status
}) {
  // kpi logging should be non-blocking so let's be defensive, especially important as we add new methods or change their names
  const methodsThatQueueEvents = [INIT]
  const methodsThatTrackEvents = [CHECKOUT, GET_SRC_PROFILE, AUTHENTICATION_METHODS_LOOKUP]

  let resource, operation, eventData
  if (methodName === INIT) initPayload = payload[0]
  if (methodName === CHECKOUT) dcfName = state.maskedCard?.dcf?.name

  if (!KPI_METHOD_DATA[methodName] || !kpiLogger) return

  try {
    resource = KPI_METHOD_DATA[methodName].resource
    operation = KPI_METHOD_DATA[methodName].operation

    eventData = {
      ...payload[0],
      ...result,
      error
    }

    if (error || !methodsThatQueueEvents.includes(methodName)) {
      kpiLogger.flush()

      if (methodsThatTrackEvents.includes(methodName)) {
        if (methodName === CHECKOUT) {
          modifyKpiLoggerHeader(payload, state, kpiLogger)
        }

        kpiLogger.track(
          buildEvent(resource, operation, error ? FAILED : status || COMPLETED, eventData)
        )
      }
      return
    }

    kpiLogger.queue(buildEvent(resource, operation, COMPLETED, eventData))
  } catch (e) {
    if (error || !methodsThatQueueEvents.includes(methodName)) {
      kpiLogger.flush()
    }

    if (process.env.NODE_ENV !== 'production' || window.location.href.includes('localhost')) {
      console.error(
        'Something went wrong while trying to prepare data for kpi logging. Ensure you have set the correct constants and data if available.',
        e
      )
    }
  }
}

export function buildEvent(resource, operation, state, data) {
  const decodedObject =
    data?.checkoutResponse && data?.idToken ? jwtDecode(data?.idToken) : undefined
  const correlationId = decodedObject?.srcCorrelationId
  const eventData = {
    resource,
    operation,
    state,
    correlationId, // correlationId should come from the SDK, not the src-correlation-id/x-correlation-id header seen in some network responses
    data: formatEvent(operation, data, state)
  }

  return removeEmptyValues(eventData)
}

function formatEvent(operation, data) {
  let threeDSMethod

  const dpaData = {
    dpaId: initPayload?.srciDpaId
  }

  const srciData = {
    srciId: initPayload?.srcInitiatorId,
    srciActionCode: data?.srciActionCode
  }

  const customInputData = data?.dpaTransactionOptions?.customInputData

  const errorData = {
    reason: data?.error?.reason,
    message: data?.error?.message,
    errorDetails: {
      message: data?.error?.details?.message || data?.error?.message || data?.error?.details
    }
  }

  switch (operation) {
    case KPI_METHOD_DATA[GET_SRC_PROFILE].operation:
      return {
        dpaData,
        srciData,
        transactionData: {
          transactionId: data?.srciTransactionId,
          initialAmount: data?.dpaTransactionOptions?.transactionAmount?.transactionAmount,
          initialCurrency: data?.dpaTransactionOptions?.transactionAmount?.transactionCurrencyCode
        },
        dcfData: {
          ...(customInputData && { dcfExperience: customInputData['com.mastercard.dcfExperience'] })
        },
        data,
        errorData
      }
    case KPI_METHOD_DATA[INIT].operation:
      return {
        dpaData: {
          ...dpaData,
          name: data?.dpaData?.dpaPresentationName,
          billingPreference: data?.dpaTransactionOptions?.dpaBillingPreference,
          shippingPreference: data?.dpaTransactionOptions?.dpaShippingPreference,
          consumerEmailAddressRequested: data?.dpaTransactionOptions?.consumerEmailAddressRequested,
          consumerPhoneNumberRequested: data?.dpaTransactionOptions?.consumerPhoneNumberRequested,
          consumerNameRequested: data?.dpaTransactionOptions?.consumerNameRequested,
          merchantCategoryCode: data?.dpaTransactionOptions?.merchantCategoryCode,
          merchantCountryCode: data?.dpaTransactionOptions?.merchantCountryCode,
          dpaLocale: data?.dpaTransactionOptions?.dpaLocale,
          transactionType: data?.dpaTransactionOptions?.transactionType,
          payloadTypeIndicatorCheckout: data?.dpaTransactionOptions?.payloadTypeIndicatorCheckout,
          payloadTypeIndicatorPayload: data?.dpaTransactionOptions?.payloadTypeIndicatorPayload,
          confirmPayment: data?.dpaTransactionOptions?.confirmPayment,
          dynamicDataType: data?.dpaTransactionOptions?.paymentOptions?.[0]?.dynamicDataType
        },
        srciData,
        transactionData: {
          transactionId: data?.srciTransactionId,
          initialAmount: data?.dpaTransactionOptions?.transactionAmount?.transactionAmount,
          initialCurrency: data?.dpaTransactionOptions?.transactionAmount?.transactionCurrencyCode
        },
        dcfData: {
          ...(customInputData && { dcfExperience: customInputData['com.mastercard.dcfExperience'] })
        },
        errorData
      }
    case KPI_METHOD_DATA[CHECKOUT].operation:
      return {
        dpaData: {
          ...dpaData,
          merchantRequestedTransactionAuthentication: isMerchantRequestedTransactionAuthentication(
            data.dpaTransactionOptions?.authenticationPreferences?.payloadRequested
          )
        },
        srciData,
        dcfData: {
          name: data?.checkoutResponse?.maskedCard?.dcf?.name || dcfName,
          applicationType: data?.checkoutResponse?.maskedCard?.dcf?.applicationType
        },
        cardData: {
          cardId: data?.checkoutResponse?.srcDigitalCardId
        },
        consumerData: {
          clientProvidedConsumerEmailAddress:
            !!data?.checkoutResponse?.maskedConsumer?.maskedEmailAddress,
          clientProvidedConsumerPhoneNumber:
            !!data?.checkoutResponse?.maskedConsumer?.maskedConsumerMobileNumber,
          clientProvidedConsumerNationalId: !!data?.checkoutResponse?.maskedConsumer?.nationalId,
          consumerFirstNameProvided: !!data?.checkoutResponse?.maskedConsumer?.maskedFirstName,
          consumerLastNameProvided: !!data?.checkoutResponse?.maskedConsumer?.maskedLastName,
          consumerId: data?.checkoutResponse?.maskedConsumer?.srcConsumerId
        },
        transactionData: {
          transactionId: initPayload?.srciTransactionId,
          checkoutActionCode: data?.dcfActionCode
        },
        complianceSettings: data?.complianceSettings,
        appInstanceData: {
          rememberMeConsent: data?.complianceSettings?.complianceResources
            ? data?.complianceSettings?.complianceResources?.some(
                (resource) => resource.complianceType === 'REMEMBER_ME'
              )
            : false,
          complianceType:
            data?.complianceSettings?.complianceResources &&
            data?.complianceSettings?.complianceResources?.map(
              (resource) => resource.complianceType
            )
        },
        authenticationData: {
          isRecognitionTokenPresent: !!data?.recognitionToken
        },
        errorData
      }
    case KPI_METHOD_DATA[AUTHENTICATION_METHODS_LOOKUP].operation:
      threeDSMethod = data?.authenticationMethods?.find((m) => m.authenticationMethodType === '3DS')
      return {
        dpaData,
        srciData,
        cardData: {
          cardId: data?.accountReference?.srcDigitalCardId,
          cardVerificationData: {
            threeDsAuthenticationReason: data?.authenticationContext?.authenticationReasons?.[0],
            threeDsServerTransactionId:
              threeDSMethod?.methodAttributes?.threeDsData?.threeDsOutputData?.dsTransID
          }
        },
        transactionData: {
          transactionId: initPayload?.srciTransactionId
        },
        errorData
      }
    default:
      console.error(
        `Could not generate analytics metadata for ${operation}. Method type is invalid.`
      )
      return {}
  }
}

function isObject(potentialObject) {
  return potentialObject && typeof potentialObject === 'object' && !Array.isArray(potentialObject)
}

function isMerchantRequestedTransactionAuthentication(payloadRequested) {
  return payloadRequested === PayloadRequested.AUTHENTICATED
}

function removeEmptyValues(obj) {
  const newObj = {}

  Object.keys(obj).forEach((key) => {
    const value = obj[key]

    if (isObject(value)) {
      const nestedObj = removeEmptyValues(value)

      if (Object.keys(nestedObj).length > 0) {
        newObj[key] = nestedObj
      }
    } else if (value || [0, false].includes(value)) {
      newObj[key] = value
    }
  })

  return newObj
}

function modifyKpiLoggerHeader(payload, state, kpiLogger) {
  try {
    const programUserInterfaceOptions = state.profiles[0]?.programUserInterfaceOptions

    if (programUserInterfaceOptions) {
      kpiLogger.headers['program-id'] = programUserInterfaceOptions.programId
    } else {
      kpiLogger.headers['program-id'] = payload[0]?.profileOptOut
        ? MPT_PROGRAM_ID
        : DEFAULT_PROGRAM_ID
    }
  } catch (e) {
    console.error('Something went wrong while trying to set kpi logger header', e)
  }
}
